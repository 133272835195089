import ApiService from "@/common/api.service";
import {API_OMS_URL} from "@/common/config";
import JwtService from "@/common/jwt.service";
import HelperService from "@/common/services/helper.service";

const PopupService = {
    getTable(type = "", item_id, filter = "", filter_page = {}){
        return ApiService.get(API_OMS_URL+'/popup/table/'+type+'/'+item_id, {
            headers: ApiService.getHeader(),
            params : {type : type, item_id : Number(item_id), filter : filter , filter_page : filter_page}
        })
    },
    getEdit(type = "", item_id, payload = {}){

        if(!HelperService.isEmpty(payload['order_id'])){
            return ApiService.get(API_OMS_URL+'/popup/order/'+payload['order_id']+'/edit/'+type+'/'+Number(item_id), {
                headers: ApiService.getHeader()
            })
        } else {
            return ApiService.get(API_OMS_URL+'/popup/edit/'+Number(item_id), {
                headers: ApiService.getHeader(),
                params : {type : type, item_id : Number(item_id)}
            })
        }
    },
    saveEdit(item_id, data, type, payload = {}){

        if(!HelperService.isEmpty(payload['order_id'])){
            return ApiService.put(API_OMS_URL+'/popup/order/'+payload['order_id']+'/edit/'+type+'/'+ Number(item_id), {data : data}, {
                headers: ApiService.getHeader(),
            })

        } else {
            return ApiService.post(API_OMS_URL+'/popup/edit/'+Number(item_id), data, {
                headers: {
                    "Content-type": "multipart/form-data",
                    "Authorization-Token": `${JwtService.getToken()}`,
                }
            })
        }
    },
    change(type = "", entity_id, data = [], payload = {}){
        return ApiService.put(API_OMS_URL+'/popup/order/'+payload['order_id']+'/edit/'+type+'/'+ entity_id + '/change', {type : type, entity_id : Number(entity_id), current_data : data, order_id : Number(payload['order_id'])}, {
            headers: ApiService.getHeader(),
        })
    },
    getUniversal(type = "", entity_id, additional = {}){
        return ApiService.get(API_OMS_URL+'/popup/universal/'+type+'/'+ entity_id, {
            headers: ApiService.getHeader(),
            params : {type : type, entity_id : entity_id, additional : additional}
        })
    },
    getChangedUniversal(type = "", entity_id, additional = {}, data = []){
        return ApiService.put(API_OMS_URL+'/popup/universal/'+type+'/'+ entity_id + '/change', {type : type, entity_id : entity_id, data : data, additional : additional}, {
            headers: ApiService.getHeader(),
        })
    },
    saveUniversal(type = "", entity_id, data, additional = {}){
        return ApiService.put(API_OMS_URL+'/popup/universal/'+type+'/'+ entity_id, {type : type, entity_id : entity_id, data : data, additional : additional}, {
            headers: ApiService.getHeader(),
        })
    },
    xlsx(type = "", item_id, filter = "", filter_page = {}){
        return ApiService.post(API_OMS_URL+'/popup/table/'+type+'/'+item_id+ '/xlsx', {type : type, item_id : Number(item_id), filter : filter, filter_page : filter_page}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },


}

export default PopupService;



