<template>
  <div id="app">

      <div class="content flex flex-row" v-if="isAuthenticated && this.$route.name !== 'shipments_email'">

          <v-left-menu :status="getMenuStatus" />

          <div class="content_right">

            <v-header />

            <div class="container max-md:px-reg_10 bg-reg_gray md:p-reg_20 md:pb-reg_110 lg:p-reg_40 lg:pb-reg_120">
                <transition>
                  <router-view v-if="loaded" />
                </transition>

                <collect-return-popup v-if="popups.collect_return.show" @hide="hidePopups" :item_id="popups.collect_return.id"  />
                <collect-b2b-return-popup v-if="popups.collect_return_b2b.show" @hide="hidePopups" :item_id="popups.collect_return_b2b.id"  />
                <collect-popup v-else-if="popups.collect_item.show" @hidePopup="hidePopups" :hash="popups.collect_item.hash" />

            </div>
          </div>

      </div>

      <transition v-else>
          <router-view />
      </transition>

      <notification v-if="popups.notification.show" :title="popups.notification.title" :notification="popups.notification.text" :type="popups.notification.type" @notificationHide="notificationHide" />

      <shipment-detail v-if="popups.shipment_detail.show" :id="popups.shipment_detail.id" />
      <shipment-emails v-if="popups.shipment_emails.show" :id="popups.shipment_emails.id" />

      <collect-popup-b2b v-if="popups.collect_b2b.show" @hide="hidePopups" :hash="popups.collect_b2b.hash" />


      <shipment-detail-b2b v-if="popups.shipment_b2b_detail.show" :id="popups.shipment_b2b_detail.id" />
      <notification-ui v-if="popups.notification_ui.show" @hide="hidePopups"  />

      <popup-edit
          v-if="popups.edit.show"
          :type="popups.edit.type"
          :item_id="popups.edit.id"
          :payload="popups.edit.payload"
      />

    <popup-universal
        v-if="popups.universal.show"
        :type="popups.universal.type"
        :entity_id="popups.universal.entity_id"
        :additional="popups.universal.additional"
    />

    <popup-rest
        v-if="popups.rest.show"
        :item_id="popups.rest.item_id"
    />

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import VLeftMenu from "@/components/leftmenu";
import VHeader from "@/components/header";
import Notification from "@/components/ui/popups/notification";
import ShipmentDetail from "@/components/ui/popups/shipment-detail";
import NotificationUi from "@/components/ui/notification";


import {GET_USER_INFO} from "@/store/actions.type";
import ShipmentEmails from "@/components/ui/popups/shipment-emails";
import ShipmentDetailB2b from "@/components/ui/popups/shipment-detail-b2b";
import PopupEdit from "@/components/ui/popupedit.vue";
import CollectPopupB2b from "@/components/ui/popups/collect-b2b";
import CollectReturnPopup from "@/components/ui/popups/collect-return.vue";
import CollectPopup from "@/components/ui/popups/collect";
import PopupUniversal from "@/components/ui/popupuniversal";
import PopupRest from "@/components/ui/popups/popup-rest.vue";
import CollectB2bReturnPopup from "@/components/ui/popups/collect-b2b-return.vue";

export default {
  name: "App",
  data: () => ({
      loaded : false,
      popups : {
        notification : {
              show : false,
              type : null,
              title : "",
              text : null
          },
          shipment_detail : {
              show : false,
              id : null
          },
          shipment_emails : {
              show : false,
              id : null
          },
          shipment_b2b_detail : {
            show : false,
            id : null
          },
          notification_ui : {
            show : false,
          },
          edit : {
              show : false,
              type : null,
              id : null,
              payload : {}
          },
          collect_b2b : {
              show : false,
              hash : null
          },
          collect_return : {
              show : false,
              id : null
          },
          collect_return_b2b : {
              show : false,
              id : null
          },
          collect_item : {
              show : false,
              id : null
          },
          universal : {
              show : false,
              type : null,
              entity_id : null,
              additional: {}
          },
          rest : {
            show : false,
            product_id : null,
            additional: {}
          }
      }
  }),
  watch: {
      $route: 'hidePopups',
      "isAuthenticated" : "getInfo"
  },
  components: {
    CollectB2bReturnPopup,
    PopupUniversal,
    PopupRest,
    CollectPopup,
    CollectReturnPopup,
    PopupEdit,
    ShipmentDetailB2b, ShipmentEmails, ShipmentDetail, Notification, VLeftMenu, VHeader, NotificationUi, CollectPopupB2b},
  computed: {
      ...mapGetters(["isAuthenticated", "getMenuStatus"]),
      currentRoute(){
        return this.$route.name;
      },
  },
  methods : {
      _popup_edit_show(type, item_id, payload = {}){
          this.popups['edit'].show = true;
          this.popups['edit'].type = type;
          this.popups['edit'].id = Number(item_id);
          this.popups['edit'].payload = payload;
      },
      _popup_edit_hide(){
          this.popups['edit'].type = null;
          this.popups['edit'].show = false;
          this.popups['edit'].id = null;
          this.popups['edit'].payload = {};
      },
      _popup_universal_show(type, entity_id, additional = {}){
          this.popups['universal'].show = true;
          this.popups['universal'].type = type;
          this.popups['universal'].entity_id = Number(entity_id);
          this.popups['universal'].additional = additional;
      },
      _popup_universal_hide(){
          this.popups['universal'].type = null;
          this.popups['universal'].show = false;
          this.popups['universal'].entity_id = null;
          this.popups['universal'].entity_id = {};
      },
      _popup_rest_show(product_id){
        this.popups['rest'].show = true;
        this.popups['rest'].item_id = Number(product_id);
      },
      _popup_rest_hide(){
        this.popups['rest'].show = false;
        this.popups['rest'].item_id = null;
        this.popups['rest'].additional = {};
      },
      notificationHide(){
          this.popups.notification.show = false;
      },
      hidePopups(){
          this.popups.notification.show = false;
          this.popups.shipment_detail.show = false;
          this.popups.shipment_b2b_detail.show = false;
          this.popups.notification_ui.show = false;
          this.popups.collect_return.show = false;
          this.popups.collect_return_b2b.show = false;

          this.popups.collect_item.show = false;
          this.popups.universal.show = false;
          this.popups.rest.show = false;

          this.scroll('show');
      },
      getInfo(){
        if(this.isAuthenticated){
          this.$store.dispatch(GET_USER_INFO).catch(() => {
            //todo
          }).finally(() => {
              this.loaded = true;
          });
        }
      },
      scroll(type = ""){
        if(type === 'hide')
          document.body.classList.add('scroll_deactivate');
        else
          document.body.classList.remove('scroll_deactivate');
      },
  },
  mounted() {

      this.$root.$on("notificationShow", (data) => {

          this.popups.notification.show = false;
          setTimeout(() => {
            this.popups.notification.type = data.type;
            this.popups.notification.title = data.title;
            this.popups.notification.text = data.text;
            this.popups.notification.show = true;
          }, 250);

      });
      this.$root.$on("notificationHide", () => {
          this.popups.notification.show = false;
      });

      this.$root.$on("notificationUiShow", () => {
          this.popups.notification_ui.show = true;
          this.scroll('hide');
      });

      this.$root.$on("shipmentDetailShow", (data) => {

          this.popups.shipment_detail.show = false;
          setTimeout(() => {
            this.popups.shipment_detail.id = data.id;
            this.popups.shipment_detail.show = true;
          }, 50);

      });
      this.$root.$on("shipmentDetailHide", () => {
        this.popups.shipment_detail.show = false;
      });

      this.$root.$on("_popup_edit_show", (popup_code, item_id, payload) => {
          this._popup_edit_show(popup_code, item_id, payload)
      });

      this.$root.$on("_popup_edit_hide", () => {
          this._popup_edit_hide()
      });

      this.$root.$on("_popup_universal_show", (code, entity_id, additional) => {
          this._popup_universal_show(code, entity_id, additional)
      });

      this.$root.$on("_popup_universal_hide", () => {
        this._popup_universal_hide()
      });

      this.$root.$on("_popup_rest_show", (product_id) => {
        this._popup_rest_show(product_id)
      });

      this.$root.$on("_popup_rest_hide", () => {
        this._popup_rest_hide()
      });

      this.$root.$on("_update_page_custom", () => {
          this.$router.push({query: {filter: this.$route.query['filter'], page: this.$route.query['page'], hash : Math.random()}}).catch(() => {});
      });

      this.$root.$on("shipmentDetailB2bShow", (data) => {

        this.popups.shipment_b2b_detail.show = false;
        setTimeout(() => {
          this.popups.shipment_b2b_detail.id = data.id;
          this.popups.shipment_b2b_detail.show = true;
        }, 50);

      });

      this.$root.$on("shipmentDetailB2bCollectShow", (data) => {

          this.popups.collect_b2b.show = false;
          setTimeout(() => {
            this.popups.collect_b2b.hash = data.hash;
            this.popups.collect_b2b.show = true;
          }, 50);

      });

      this.$root.$on("shipmentCollectReturnShow", (data) => {

        this.popups.collect_return.show = false;
        setTimeout(() => {
          this.popups.collect_return.id = data.id;
          this.popups.collect_return.show = true;
        }, 50);

      });

      this.$root.$on("collectReturnB2bShow", (data) => {

        this.popups.collect_return_b2b.show = false;
        setTimeout(() => {
          this.popups.collect_return_b2b.id = data.id;
          this.popups.collect_return_b2b.show = true;
        }, 50);

      });

      this.$root.$on("collectItemShow", (data) => {

        this.popups.collect_item.show = false;
        setTimeout(() => {
          this.popups.collect_item.hash = data.hash;
          this.popups.collect_item.show = true;
        }, 50);

      });

      this.$root.$on("shipmentCollectReturnHide", () => {
          this.popups.collect_return.show = false;
      });

      this.$root.$on("shipmentDetailB2bHide", () => {
        this.popups.shipment_b2b_detail.show = false;
      });

      this.$root.$on("shipmentDetailB2bCollectHide", () => {
        this.popups.collect_b2b.show = false;
      });

      this.$root.$on("shipmentEmailsShow", (data) => {
          this.popups.shipment_emails.show = false;
          setTimeout(() => {
            this.popups.shipment_emails.id = data.id;
            this.popups.shipment_emails.show = true;
          }, 50);
      });
      this.$root.$on("shipmentEmailsHide", () => {
          this.popups.shipment_emails.show = false;
      });

      this.getInfo();

  },
}
</script>

<style type="text/css">
 body{
   --tw-bg-opacity: 1;
   background-color: rgb(245 245 246 / var(--tw-bg-opacity));
 }

 .vs__search, .vs__search:focus{
   border: none !important;
   cursor: pointer;
 }

 .vs__dropdown-toggle{
   height: 37px;
 }

 .v-select {
   margin-top: 5px;
 }

 fieldset:disabled {
     cursor: not-allowed;
 }

 fieldset:disabled  .cursor-pointer {
   cursor: not-allowed;
 }

 .cursor-not-allowed{
   cursor: not-allowed !important;
 }


</style>
