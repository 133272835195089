<template>
  <div :class="[!loaded || !loaded_filter ? 'block-spinner_page top' : '', class_block]">
    <div v-if="loaded && loaded_filter">

      <div class="flex flex-row justify-between items-center" v-if="info.length>0">

          <ul class="flex flex-row py-reg_24" :class="[with_padding_x ? 'px-reg_24' : '']" >
              <li class="flex flex-row items-center" v-for="(infoItem, info_key) in info" v-bind:key="info_key" >
                <span class="inline-flex text-xs_l_reg text-reg_gray_60">{{infoItem['title']}}: {{infoItem['value']}}</span>
                <span class="inline-flex text-xs_l_reg text-reg_gray_60 px-reg_16" v-if="info_key+1!==info.length">⁄</span>
              </li>
          </ul>

          <div class="flex" v-if="is_xlsx">
            <button class="flex flex-row items-center cursor-pointer hover:bg-reg_blue_10 border border-reg_gray_75 rounded py-reg_8 px-reg_12 mr-reg_16" @click="xlsx()">
                <svg-icon name="icon__download" class="icon__download w-reg_16 h-reg_16 stroke-reg_15 stroke-reg_gray_60 fill-reg_white" />
            </button>
          </div>
      </div>

      <div class="flex flex-row justify-between items-center" v-if="files.length>0">

        <div class="flex flex-row flex-wrap px-reg_24 py-reg_24 gap_12">
            <button class="flex flex-row text-xs_reg text-reg_blue pr-reg_15" v-for="(file) in files" v-bind:key="file['code']" @click="_save_file(file['code'], file['type'])" >
              <svg-icon name="icon__download" class="icon__download w-reg_16 h-reg_16 stroke-reg_15 stroke-reg_blue fill-transparent mr-reg_8" />
                  {{file['name']}}
            </button>
        </div>

      </div>

      <div class="flex flex-row justify-between items-center" v-if="!!tab">
          <collect-tab v-if="tab === 'collect'"/>
          <setting-tab v-else-if="tab === 'settings'"/>
          <shipments-tab v-else-if="tab === 'shipments'"/>
          <deliveries-tab v-else-if="tab === 'deliveries'"/>
          <order-request-tab v-else-if="tab === 'order_request'" />
      </div>

      <div class="flex flex-row justify-between items-center px-reg_24 pb-reg_24" v-if="popup_edit_code">
          <button @click="_popup_edit(popup_edit_code, 0, payload)" type="button" class="block rounded bg-reg_green hover:bg-reg_green_100 font-medium text-reg_white text-xs_reg py-reg_8 px-reg_20">{{popup_edit_title ? popup_edit_title : 'Добавить'}}</button>
      </div>

      <div :class="class_table_block">
        <div class="table w-full table-auto" :class="[with_padding_x ? 'px-reg_24 ' : '']" v-if="items.length > 0">
          <div class="table-header-group">
            <div class="table-row">
              <div v-for="(th, th_key) in table" v-bind:key="th_key" class="table-cell align-middle p-reg_12 border-y border-reg_gray_10 cursor-pointer hover:bg-reg_gray"
                   :class="[Number(th['size']) > 0 && Number(th['size']) < 100 ? 'w-reg_'+th['size']+'_100' : '', Number(th['size']) >= 100 ? 'w-reg_'+th['size'] : '', th['type'] !== 'delete' ? 'min-w-reg_100' : 'w-reg_2_5_100']"
                >
                <p class="text-xs_l_reg text-reg_black font-semibold">
                  {{ th['name'] }}</p>
              </div>
            </div>

          </div>
          <div class="table-row-group">

            <div class="table-row" v-for="(order) in items" v-bind:key="order['id']"  >
              <div class="table-cell align-middle p-reg_12 border-b"  v-for="(th, th_key) in table" v-bind:key="th_key" >

                  <router-link v-if="th['type'] === 'order_edit'" class="text-xs_l_reg text-reg_blue" :to="{ name: 'order_edit', params: { id: order[th['code']]} }"> {{order[th['code']]}}</router-link>
                  <button v-else-if="th['type'] === 'b2b_item'" class="text-xs_l_reg text-reg_blue" @click="_show_b2b_item(order['id'])">{{order[th['code']]}}</button>
                  <button v-else-if="th['type'] === 'shipment_item'" class="text-xs_l_reg text-reg_blue" @click="_show_shipment_item(order[th['prop_code']])">{{order[th['code']]}}</button>
                  <button v-else-if="th['type'] === 'b2b_collect_item'" class="text-xs_l_reg text-reg_blue" @click="_show_b2b_collect_item(order['hash'])">{{order[th['code']]}}</button>
                  <button v-else-if="th['type'] === 'collect_return_item'" class="text-xs_l_reg text-reg_blue" @click="_show_collect_return_item(order['real_id'])">{{order[th['code']]}}</button>
                  <button v-else-if="th['type'] === 'collect_return_b2b_item'" class="text-xs_l_reg text-reg_blue" @click="_show_collect_return_b2b_item(order['id'])">{{order[th['code']]}}</button>

                  <button v-else-if="th['type'] === 'collect_item'" class="text-xs_l_reg text-reg_blue" @click="_show_collect_item(order['hash'])">{{order[th['code']]}}</button>

                  <p v-else-if="th['type'] === 'text_color'" class="text-xs_l_reg" :class="[order['color'].length > 0 ? 'text-reg_'+order['color'] : 'text-reg_black']" v-html="order[th['code']]">{{order[th['code']]}}</p>
                  <p v-else-if="th['type'] === 'text_empty'" class="text-xs_l_reg" :class="[order[th['check_empty']] ? 'text-reg_'+th['color_empty'] : 'text-reg_black']" v-html="order[th['code']]">{{order[th['code']]}}</p>

                  <div class="flex flex-row items-center" v-else-if="th['type'] === 'text_with_icon'" >

                        <span class="block-circle relative border-2 inline-flex items-center justify-center w-reg_16 h-reg_16 rounded-full border-reg_green bg-reg_green" v-if="order['icon_status'] === 'success'" >
                           <svg-icon name="icon__check" class="hidden icon__check w-reg_24 h-reg_24" style="display: inline-flex;fill: #ffffff;" />
                        </span>
                        <span class="block-circle relative bg-reg_red inline-flex items-center justify-center rounded-full w-reg_16 h-reg_16" v-else-if="order['icon_status'] === 'error'" >
                           <svg-icon name="icon__close-2" class="icon__close-2 absolute w-reg_12 h-reg_12 fill-reg_white" />
                        </span>
                        <div class="inline-flex items-center justify-center bg-reg_white border-2 border-reg_gray_75 rounded-full w-reg_16 h-reg_16" v-else-if="order['icon_status'] === 'in_process'"></div>

                        <span class="text text-xs_l_reg pl-reg_15" :class="[order['color_icon_text'].length > 0 ? 'text-reg_'+order['color_icon_text'] : 'text-reg_black']">{{order[th['code']]}}</span>

                  </div>


                  <div class="flex flex-row items-center" v-else-if="th['type'] === 'product'" >
                    <div>
                        <img :src="order[th['product_data'].picture]" class="w-reg_40">
                    </div>
                    <div class="ml-reg_14">
                        <a target="_blank" :href="order[th['product_data'].link]" class="text-xs_l_reg text-reg_blue" v-html="order[th['product_data'].name]">{{order[th['product_data'].name]}}</a>
                        <p class="text-xs_l_reg text-reg_black_75 pt-reg_3">Код товара: {{order[th['product_data'].code]}}</p>
                        <p class="text-xs_l_reg text-reg_black_75 pt-reg_3" v-if="order['barcodes']">Штрихкоды: {{order['barcodes']}}</p>

                        <p class="text-xs_l_reg text-reg_black_75 pt-reg_3 text-reg_green" v-if="order['is_on_order']">Матрица+: Да</p>
                        <p class="text-xs_l_reg text-reg_black_75 pt-reg_3 text-reg_red" v-if="order['is_on_order_2']">Матрица+: Нет</p>
                    </div>
                  </div>

                  <date-picker v-else-if="th['type'] === 'date' && order['is_hide']!==true"
                      :lang="ru"
                      :currentValue="order[th['code']]"
                      :range = "th['range']"
                      format="DD.MM.YYYY"
                      v-model="order[th['code']]"
                      type="date"
                      valueType="DD.MM.YYYY"
                      :disabled="order['is_cancel']"
                      :clearable="order['hide_clearable'] !== true"
                      @change="_update_item(th['code'], order['id'], order[th['code']], order)"
                  />

                  <ui-input v-else-if="th['type'] === 'quantity'"
                      label=""
                      type="number"
                      :name_custom="'product_'+'_'+order['id']+order[th['code']]"
                      :name="order[th['code']]"
                      v-model="order[th['code']]"
                      class_block=""
                      class_label=""
                      class_input="text-xs_l_reg text-reg_black border border-reg_gray_75 w-reg_60 rounded py-reg_8 px-reg_8"
                      placeholder=""
                      :min="order['min'] ? order['min'] : 0"
                      :max="order['max'] ? order['max'] : 10000"
                      :step="1"
                      @change="_update_item(th['code'], order['id'], order[th['code']], order)"
                      :disabled="order['is_cancel']"
                  />

                  <ui-select
                      v-else-if="th['type'] === 'select'"
                      :name="'product_'+'_'+order['id']+order[th['code']]"
                      v-model="order[th['code']]"
                      :options="th['options']"
                      :id="'product_'+'_'+order['id']+order[th['code']]"
                      class_block="table md:w-full xl:w-1/2 2xl:w-2/6 2xl:mt-0 mr-reg_24 cursor-default"
                      class_label="attention relative text-sm_reg text-reg_black font-semibold"
                      class_description="block text-reg_gray_50 text-xs_reg mt-reg_4"
                      label=""
                      :searchable="false"
                      :is_open_dropdown="true"
                      :reduce="option => option.code"
                      label_select="title"
                      style="width: 200px;"
                      :disabled="order['is_cancel']"
                      @option:selected="(option) => {
                           option = {...option};
                           order[th['code']] = option['code'];
                          _update_item(th['code'], order['id'], order[th['code']], order, option['is_final']);
                      }"
                  />

                  <p class="text-xs_l_reg text-reg_black" v-else-if="th['type'] === 'text' && typeof th['code'] === 'string'" v-html="order[th['code']]">{{order[th['code']]}}</p>

                  <p class="text-xs_l_reg text-reg_black" v-else-if="th['type'] === 'popup_edit' && typeof th['code'] === 'string'">
                    <button type="button" @click="_popup_edit(order['popup_code'] ? order['popup_code'] : th['popup_code'], order['id'], payload)" class="text-reg_blue underline" style="text-align: left;">{{order[th['code']]}}</button>
                  </p>

                  <p class="text-xs_l_reg text-reg_black" v-else-if="th['type'] === 'popup_universal' && typeof th['code'] === 'string'">
                    <button type="button" @click="_popup_universal(order['popup_code'] ? order['popup_code'] : th['popup_code'], order[th['popup_item_code']], payload)" class="text-reg_blue text-xs_l_reg" style="text-align: left;">
                      <span v-if="order[th['code']]">{{order[th['code']]}}</span><span v-else>-</span></button>
                  </p>

                <p class="text-xs_l_reg text-reg_black" v-else-if="th['type'] === 'popup' && typeof th['code'] === 'string'">
                      <button @click="_popup(th['popup_code'], order['id'], order[th['popup_code_item']])" class="text-xs_l_reg text-reg_blue">{{order[th['code']]}}</button>
                  </p>

                  <div v-else-if="th['type'] === 'text' && th['title'].length>0" v-for="(th_m, th_key_m) in th['code']" v-bind:key="th_key_m">
                    <p class="text-xs_l_reg" v-if="order[th_m]">{{th['title'][th_key_m]}}</p>
                    <p class="text-xs_l_reg " :class="[th['color'][th_key_m] === 'gray' ? 'text-reg_gray_50' : 'text-reg_black']" v-if="order[th_m]">{{order[th_m]}}</p>
                  </div>

                  <div v-else-if="th['type'] === 'text'">
                    <p class="text-xs_l_reg" :class="[th['color'][th_key_m] === 'gray' ? 'text-reg_gray_50' : 'text-reg_black']" v-for="(th_m, th_key_m) in th['code']" v-bind:key="th_key_m">{{order[th_m]}}</p>
                  </div>

                  <div v-else-if="th['type'] === 'file'">
                      <button class="flex flex-row items-center cursor-pointer hover:bg-reg_blue_10 border border-reg_gray_75 rounded p-reg_8" @click="_load_file(th['code'], th['type_file'], { id : order['id']})">
                        <svg-icon name="icon__download" class="icon__download w-reg_16 h-reg_16 stroke-reg_15 stroke-reg_gray_60 fill-reg_white" />
                      </button>
                  </div>


                <div v-else-if="th['type'] === 'checkbox'">
                    <checkbox
                        :name="th['type'] + '_' +order['id']"
                        v-model="order[th['code']]"
                        label=""
                        @input="(name, value) => {
                          order[th['code']] = value;
                          _update_item();
                        }"
                    />
                </div>


                <button v-else-if="th['type'] === 'delete' && !order['forbidden_delete']" type="button" @click="_delete_item(order['id'])" class="flex opacity-50 w-max  hover:bg-reg_pink py-reg_3 px-reg_5 rounded">
                  <svg-icon name="icon__delete" class="item_icon-block icon__delete w-reg_16 h-reg_16 fill-reg_red" />
                </button>

                <p v-else></p>
              </div>
            </div>
          </div>
        </div>
        <not-found :class_block="class_not_found" text="Ничего не найдено" v-else />



        <v-pagination
            v-if="items.length > 0 && pagination_data.total > pagination_data.per_page"
            :current="pagination_data.current"
            :total="pagination_data.total"
            @setPage="setPage"
            :perPage="pagination_data.per_page"
        />

      </div>

    </div>
  </div>
</template>

<script>

import VPagination from "@/components/ui/pagination";
import NotFound from "@/components/ui/block/not-found";
import SvgIcon from "@/components/ui/svgicon";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ru from 'vue2-datepicker/locale/ru';
import UiInput from "@/components/ui/form/elements/input";
import UiSelect from "@/components/ui/form/elements/select";
import CollectTab from "@/components/ui/block/tabs/collect";
import SettingTab from "@/components/ui/block/tabs/settings";
import DeliveriesTab from "@/components/ui/block/tabs/deliveries";
import OrderRequestTab from "@/components/ui/block/tabs/order-request";
import ShipmentsTab from "@/components/ui/block/tabs/shipments";
import Checkbox from "@/components/ui/form/elements/checkbox";

export default {
  name: "v-table",
  components: {
    Checkbox,
    ShipmentsTab,
    OrderRequestTab,
    DeliveriesTab, SettingTab, CollectTab, SvgIcon, VPagination, NotFound, DatePicker, UiInput, UiSelect},
  data: () => ({
    ru : ru
  }),
  props: {
      with_padding_x : {
          type : Boolean,
          default : true
      },
      class_block : {
          type : String,
          default : "bg-reg_white rounded shadow-reg_sm"
      },
      class_table_block : {
        type : String,
        default : "popup-table-scroll"
      },
      class_not_found : {
          type : String,
          required: false,
      },
      tab : {
          required: false,
      },
      table : {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      info: {
        type: Array,
        required: false,
      },
      loaded : {
        type: Boolean,
        required: true,
      },
      loaded_filter : {
        type: Boolean,
        required: true,
      },
      pagination_data : {
          type: Object,
          required: true,
      },
      is_xlsx : {
        type: Boolean,
        required: false,
      },
      popup_edit_code : {
        type : String,
        required: false,
      },
      popup_edit_title : {
        type : String,
        required: false,
      },
      files : {
          type: Array,
          required: false,
          default: () => []
      },
      payload : {}
  },
  methods: {
      setPage(page){
          this.$emit('setPage', page);
      },
      xlsx(){
        this.$emit('xlsx');
      },
      _popup(type, item_id, filter = ""){
        this.$emit('_popup', type, item_id, filter);
      },
      _popup_edit(type, item_id, payload = {}){
         this.$root.$emit('_popup_edit_show', type, item_id, payload);
      },
      _popup_universal(type, item_id, payload = {}){
          this.$root.$emit('_popup_universal_show', type, item_id, payload);
      },
      _update_item(type, item_id, value, entity, is_cancel = false){
        this.$emit('_update_item', type, item_id, value, entity, is_cancel);
      },
      _delete_item(item_id){
          this.$emit("_delete_item", item_id);
      },
      _show_b2b_item(id){
          this.$root.$emit("shipmentDetailB2bShow", {id : id});
      },
      _show_shipment_item(id){
        this.$root.$emit("shipmentDetailShow", {id : id});
      },
      _show_b2b_collect_item(hash){
        this.$root.$emit("shipmentDetailB2bCollectShow", {hash : hash});
      },
      _show_collect_return_item(id){
        this.$root.$emit("shipmentCollectReturnShow", {id : id});
      },
      _show_collect_return_b2b_item(id){
          this.$root.$emit("collectReturnB2bShow", {id : id});
      },
      _show_collect_item(hash){
        this.$root.$emit("collectItemShow", {hash : hash});
      },
      _save_file(code, type, params = {}){
          this.$emit('_save_file', code, type, params);
      },
      _load_file(code, type, params = {}){
        this.$emit('_load_file', code, type, params);
      }
  }
}
</script>

<style scoped>

</style>